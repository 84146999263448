<template>
  <b-container>
    <b-row>
      <b-col lg="3" md="12" class="mt-4">
        <b-card class="mb-2" bg-variant="dark" text-variant="white">
          <h1>Console</h1>
        </b-card>
      </b-col>
      <b-col lg="5" md="12" class="mt-4">
        <b-card class="mb-2" bg-variant="dark" text-variant="white">
          <b-card-title>Queues</b-card-title>
          <b-progress show-value striped :max="stats.queuesTotal" class="mb-1">
            <b-progress-bar
              variant="success"
              :value="stats.queues.processing"
              animated
            ></b-progress-bar>
            <b-progress-bar
              variant="primary"
              :value="stats.queues.new"
            ></b-progress-bar>
            <b-progress-bar
              variant="danger"
              :value="stats.queues.failed"
            ></b-progress-bar>
          </b-progress>
        </b-card>
      </b-col>
      <b-col lg="4" md="12" class="mt-4">
        <b-card class="mb-2" bg-variant="dark" text-variant="white">
          <span>Stats for the last hour</span>
          <br />
          <b-button variant="success" size="sm">
            Minted:
            <b-badge variant="light">{{ this.stats.mints.mint || 0 }}</b-badge>
          </b-button>
          <b-button variant="warning" size="sm" class="mx-2">
            Collected
            <b-badge variant="light">{{
              this.stats.mints.collect || 0
            }}</b-badge>
          </b-button>
          <b-button variant="info" size="sm">
            Refunds
            <b-badge variant="light">{{
              this.stats.mints.refund || 0
            }}</b-badge>
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="12" class="mt-4">
        <b-card
          v-if="collectionItems.length"
          class="mb-2"
          bg-variant="dark"
          text-variant="white"
        >
          <b-card-title>Open Mints</b-card-title>
          <b-table
            dark
            striped
            :fields="tableFields"
            :items="collectionItems"
            responsive
          >
            <template #cell(client)="data">
              <b-link :to="`/${data.value}`">{{ data.value }}</b-link>
            </template>
            <template #cell(title)="data">
              <b-link :to="`/${data.item.client}/${data.item.id}`">{{
                data.value
              }}</b-link>
            </template>
            <template #cell(whitelist_end)="data">
              <b-link :to="`/console/whitelist/${data.item.id}`">{{
                data.value
              }}</b-link>
            </template>
          </b-table>
          <small>Last updated: {{ lastUpdated }}</small>
        </b-card>
      </b-col>
      
      <b-col cols="12" class="mt-4">
        <b-card
          v-if="collectionItemsEnd.length"
          class="mb-2"
          bg-variant="dark"
          text-variant="white"
        >
          <b-card-title>Mints with End Date</b-card-title>
          <b-table
            dark
            striped
            :fields="tableFields"
            :items="collectionItemsEnd"
            responsive
          >
            <template #cell(client)="data">
              <b-link :to="`/${data.value}`">{{ data.value }}</b-link>
            </template>
            <template #cell(title)="data">
              <b-link :to="`/${data.item.client}/${data.item.id}`">{{
                data.value
              }}</b-link>
            </template>
            <template #cell(whitelist_end)="data">
              <b-link :to="`/console/whitelist/${data.item.id}`">{{
                data.value
              }}</b-link>
            </template>
          </b-table>
          <small>Last updated: {{ lastUpdated }}</small>
        </b-card>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import { db } from "@/firebase";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { countBy } from "lodash";

export default {
  name: "Console",
  data() {
    return {
      unsubscribeq: null,
      unsubscribeqQ: null,
      unsubscribeqM: null,
      collections: [],
      collectionsEnd: [],
      lastUpdated: null,
      stats: {
        queues: {},
        queuesTotal: 0,
        mints: {},
        mintsTotal: 0,
      },
    };
  },
  async created() {
    // Reset console styles
    document.body.style.backgroundImage =
      "url('/img/background.426aa9b6.jpeg')";
    document.getElementById("nav").style.backgroundColor = "black";
    document.getElementById(
      "logo"
    ).style.backgroundImage = "url('/img/RetroNFTs-logo.27d3e434.png')";

    const isAdmin = this.$store.getters.isAdmin;
    const userClients = this.$store.getters.userClients;
    const oneHourAgo = moment().subtract(1, "hour").toDate();
    const now = moment().toDate();

    let q;
    let qEnd;
    let qQueues;
    let qMints;
    if (isAdmin) {
      q = query(
        collection(db, "collections"),
        where("endDate", "==", null),
        orderBy("startDate", "desc"),
        limit(100)
      );
      qEnd = query(
        collection(db, "collections"),
        where("endDate", "!=", null),
        orderBy("endDate", "desc"),
        limit(10)
      );
      qQueues = query(
        collection(db, "queues"),
        where("status", "!=", "complete"),
        limit(100)
      );
      qMints = query(
        collection(db, "queues"),
        where("status", "==", "complete"),
        where("lastUpdated", ">=", oneHourAgo),
        where("lastUpdated", "<=", now)
      );
    } else if (userClients !== undefined && userClients.length <= 10) {
      q = query(
        collection(db, "collections"),
        where("client", "in", userClients),
        where("endDate", "==", null),
        orderBy("startDate", "desc"),
        limit(50)
      );
      qEnd = query(
        collection(db, "collections"),
        where("client", "in", userClients),
        where("endDate", "!=", null),
        orderBy("endDate", "desc"),
        limit(5)
      );
      qQueues = query(
        collection(db, "queues"),
        where("client", "in", userClients),
        where("status", "!=", "complete"),
        limit(100)
      );
      qMints = query(
        collection(db, "queues"),
        where("client", "in", userClients),
        where("status", "==", "complete"),
        where("lastUpdated", ">=", oneHourAgo),
        where("lastUpdated", "<=", now)
      );
    } else {
      this.$router.push({ name: "ConsoleError" });
      return;
    }

    this.unsubscribeq = onSnapshot(q, (querySnapshot) => {
      const collections = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        collections.push(data);
      });
      this.lastUpdated = moment().format("MMM DD, h:mma");
      this.collections = collections;
    });

      this.unsubscribeqEnd = onSnapshot(qEnd, (querySnapshot) => {
      const collectionsEnd = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        collectionsEnd.push(data);
      });
      this.lastUpdated = moment().format("MMM DD, h:mma");
      this.collectionsEnd = collectionsEnd;
    });


    // Queue stats
    this.unsubscribeqQ = onSnapshot(qQueues, (qQueuesSnapshot) => {
      if (qQueuesSnapshot.size) {
        let qStatus = [];
        let qType = [];
        let qTotal = 0;

        qQueuesSnapshot.forEach((doc) => {
          const { status, type } = doc.data();
          qStatus.push(status);
          qType.push(type);
          qTotal++;
        });
        this.stats.queues = countBy(qStatus);
        this.stats.queuesTotal = qTotal;
      }
    });

    // Mint past hour stats
    this.unsubscribeqM = onSnapshot(qMints, (qMintsSnapshot) => {
      if (qMintsSnapshot.size) {
        let mStatus = [];
        let mType = [];
        let mTotal = 0;

        qMintsSnapshot.forEach((doc) => {
          const { status, type } = doc.data();
          mStatus.push(status);
          mType.push(type);
          mTotal++;
        });
        this.stats.mints = countBy(mType);
        this.stats.mintsTotal = mTotal;
      }
    });
  },
  destroyed() {
    this.unsubscribeq();
    this.unsubscribeqQ();
    this.unsubscribeqM();
  },
  computed: {
    collectionItems() {
      return this.collections.map((collection) => {
        return {
          id: collection.id,
          start_date: moment(collection.startDate.toDate()).format(
            "MMM DD, h:mma"
          ),
          end_date: collection.endDate ? moment(collection.endDate.toDate()).format(
            "MMM DD, h:mma"
          ) : "not set",
          type: collection.type,
          client: collection.client,
          title: collection.title,
          limit: collection.limit,
          sold_count: collection.soldCount,
          whitelist_end: collection.whitelistDate
            ? collection.whitelistDate <= collection.startDate
              ? "N/A"
              : moment(collection.whitelistDate.toDate()).format(
                  "MMM DD YYYY, h:mma"
                )
            : "",
          policylock_date: collection.policyLockDate
            ? moment(collection.policyLockDate.toDate()).format("MMM DD YYYY")
            : "",
          in_queue: collection.queueCount - collection.queuePosition,
          processing: collection.processingCount || 0,
        };
      });
    },
    collectionItemsEnd() {
      return this.collectionsEnd.map((collection) => {
        return {
          id: collection.id,
          start_date: moment(collection.startDate.toDate()).format(
            "MMM DD, h:mma"
          ),
          end_date: collection.endDate ? moment(collection.endDate.toDate()).format(
            "MMM DD, h:mma"
          ) : "not set",
          type: collection.type,
          client: collection.client,
          title: collection.title,
          limit: collection.limit,
          sold_count: collection.soldCount,
          whitelist_end: collection.whitelistDate
            ? collection.whitelistDate <= collection.startDate
              ? "N/A"
              : moment(collection.whitelistDate.toDate()).format(
                  "MMM DD YYYY, h:mma"
                )
            : "",
          policylock_date: collection.policyLockDate
            ? moment(collection.policyLockDate.toDate()).format("MMM DD YYYY")
            : "",
          in_queue: collection.queueCount - collection.queuePosition,
          processing: collection.processingCount || 0,
        };
      });
    },
    tableFields() {
      const tableKeys = Object.keys(this.collectionItems[0]);
      return tableKeys.map((key) => {
        if (key === "id") {
          return { key: "id", thClass: "d-none", tdClass: "d-none" };
        } else {
          return {
            key,
          };
        }
      });
    },
  },
};
</script>

<style></style>
